
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { TaskSchedule, TaskScheduleFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({
  components: {},
})
export class TaskScheduleList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: TaskSchedule[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: TaskScheduleFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "technician", label: "Tech", filter: false, sorter: false },
    { key: "description", label: "Description", filter: false, sorter: false },
    { key: "duration", label: "Duration", filter: false, sorter: false },
    {
      key: "complete",
      label: "Complete",
      filter: false,
      sorter: false,
    },
    {
      key: "start",
      label: "Start",
      filter: false,
      sorter: false,
    },
    {
      key: "end",
      label: "End",
      filter: false,
      sorter: false,
    },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TaskScheduleFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: TaskSchedule) {
    this.$router.push(`/part/${item.ID}`);
  }

  onDelete(item: TaskSchedule) {
    // console.log('hi', item)
    this.$emit("delete", item);
  }
}

export default TaskScheduleList;
