
import { Component, Prop } from "vue-property-decorator";
import { Job } from "@/store/modules";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";

@Component
export class JobCardInfo extends mixins(DateFormatMixin, HeMixin) {
  @Prop({ default: () => null })
  private job!: Job | null;
}

export default JobCardInfo;
