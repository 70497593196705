
import { ContentLoader } from "vue-content-loader";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: { ContentLoader }
})
export class InfoCardLoader extends Vue {
  @Prop({ default: () => 800 })
  width!: Number;

  @Prop({ default: () => 150 })
  height!: Number;

  @Prop({ default: () => 2 })
  speed!: Number;
}
export default InfoCardLoader;
