
import { Component, Prop, PropSync, Provide } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Task, TaskFilterQueryPayload } from "@/store/modules";
import TaskStatusBadge from "./detail/TaskStatusBadge.vue";

@Component({
  components: { TaskStatusBadge },
})
export class TaskList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Task[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: TaskFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  @Provide("")
  private readonly filterTaskNumber: string = "";

  page = 1;

  fields: Array<any> = [
    { key: "status", label: "Status", filter: false },
    { key: "jobNumber", label: "Task" },
    { key: "job", label: "Customer", filter: false },
    { key: "worktype", label: "Work type", filter: false, sorter: true },
    { key: "address", label: "Address", filter: false },
    // { key: "total_price", label: "Total Price", filter: false },
    { key: "datetime", label: "Created", filter: false },
    { key: "priority", label: "Priority", filter: false, sorter: true },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TaskFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  setTaskNumberChange(e: Event) {
    if (!e.target) {
      return;
    }

    const taskNumber = (e.target as HTMLInputElement).value;
    const s = { ...this.columnFilterValueSync, taskNumber };
    this.columnFilterValueSync = s;
  }
}

export default TaskList;
