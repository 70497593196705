
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import mapboxgl, { Map, LngLatLike, Marker } from "mapbox-gl";
import { mixins } from "vue-class-component";
import { DateFormatMixin } from "../../../mixins";
import { Address, Task } from "../../../store/modules";

@Component({
  components: {},
})
export default class TaskMap extends mixins(DateFormatMixin) {
  map?: Map;

  @Prop([Array, Object])
  center?: LngLatLike;

  @Prop({ default: 12, type: Number })
  zoom!: number;

  @Prop({ default: () => [], type: Array })
  tasks?: Task[];

  markers: Marker[] = [];

  @Watch("center")
  onCenterChange(center: LngLatLike) {
    this.map && this.map.setCenter(center);
  }

  // @Watch("addresses")
  // onAddressChange(addresses: string[]) {
  //   console.log('onAddressChange', addresses)
  //   this.processAddress(addresses);
  // }

  mounted() {
    console.log("mounted", this.$el);
    this.initMap();
  }

  beforeDestroy() {
    console.log("beforeDestroy");
    this.destroyMap();
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: this.$el as HTMLElement,
      style: "mapbox://styles/mapbox/light-v10",
      zoom: this.zoom,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.resize();

    if (this.center) {
      this.map.setCenter(this.center);
    }
    this.map.on("load", () => this.mapLoaded());
  }

  destroyMap() {
    if (!this.map) {
      return;
    }
    // stop all transactions
    this.map.stop();
    this.map.off("load", () => this.map);
    // explictly call remove to remove workers
    this.map.remove();
    this.map = undefined;
  }

  mapLoaded() {
    console.debug("mapLoaded");

    this.$nextTick(() => this.init());
  }

  clearMarkers() {
    this.markers.forEach((m) => m.remove());
  }

  async init() {
    const tasks = this.tasks || [];
    const addresses = [];
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (!task.job) {
        continue;
      }

      const job = task.job;

      if (!job.address && job.addressID) {
        await Address.dispatch("findOne", {
          id: +job.addressID,
        });

        const address = Address.query()
          .withAllRecursive()
          .whereId(+job.addressID)
          .first();

        if (address) {
          addresses.push(
            `${address.full_address} ${address.city} ${
              address.country && address.country.name
                ? address.country.name
                : "Canada"
            }`
          );
        }
      }
    }
    this.clearMarkers();

    const markers = (await this.processAddress(addresses)).filter(
      (n) => n !== null
    ) as Marker[];

    if (!markers.length) {
      return;
    }

    this.markers = markers;
    const center = markers[0].getLngLat();
    this.map?.setCenter(center);
    markers.forEach((marker) => {
      this.map && marker.addTo(this.map);
    });
  }

  async processAddress(addresses: string[]): Promise<Array<Marker | null>> {
    if (!addresses.length) {
      return [];
    }
    console.log("Processing", addresses);
    const endpoint = "mapbox.places";
    const token = `${process.env.VUE_APP_MAP_BOX}`;
    const baseUrl = "https://api.mapbox.com/geocoding/v5";
    // const storage = localStorage;
    // const addresses = this.addresses;
    return await Promise.all(
      addresses.map(async (address) => {
        const url = new URL(`${baseUrl}/${endpoint}/${address}.json`);
        url.searchParams.append("access_token", `${token}`);

        const response = await fetch(`${url}`);
        if (response.status !== 200) {
          return null;
        }

        const data = await response.json();

        if ("features" in data && data.features.length) {
          const marker = new Marker({
            color: "#1a6da9",
          });

          const feature = data.features[0];
          marker.setLngLat(feature.center);

          // if (!this.center) {
          //   this.center = feature.center;
          // }
          return marker;
        }

        return null;
      })
    );
    // Object.keys(params).forEach(key => url.searchParams.append(key, ''+params[key]))
  }
}
