
import { Component, Vue, Prop } from "vue-property-decorator";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";
// import { ContentLoader } from 'vue-content-loader'
// import JobCardInfo from '@/pages/job/JobCardInfo.vue';
// import { JobCardInfo } from "@/pages";
import { Job, JobInterface, Customer, Address } from "@/store/modules";

@Component({
  components: { InfoCardLoader }
})
export class JobCustomerCard extends Vue {
  @Prop([String, Number])
  readonly id!: string | number;

  private job: Job | null = null;

  private loading: boolean = true;

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  async fetchRender() {
    const id: number = +this.id;

    if (isNaN(id) || !id) {
      this.loading = false;
      return;
    }

    const job = this.findJob(id);

    if (!job) {
      this.loading = true;
      await Job.dispatch("findOne", {
        id: id,
        withCustomer: true,
        withAddress: true,
        withBillingAddress: true
      });
      this.job = this.findJob(id);
    } else {
      if (!job.customer && job.customerID) {
        await Customer.dispatch("findOne", {
          id: +job.customerID
        });
      }

      if (!job.address && job.addressID) {
        await Address.dispatch("findOne", {
          id: +job.addressID
        });
      }

      if (!job.billingAddress && job.billingaddressID) {
        await Address.dispatch("findOne", {
          id: +job.billingaddressID
        });
      }

      this.job = this.findJob(id);
    }

    this.loading = false;
  }

  findJob(id: number, related: boolean = true): Job | null {
    if (!related) {
      return Job.find(id);
    }
    return Job.query()
      .withAllRecursive()
      .whereId(id)
      .first();
  }
}

export default JobCustomerCard;
