
import { Component, Prop } from "vue-property-decorator";
import { Task } from "@/store/modules";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin, HeMixin } from "@/mixins";

@Component
export class TaskCardInfo extends mixins(
  CurrencyFormatMixin,
  DateFormatMixin,
  HeMixin
) {
  @Prop({ default: () => null })
  private task!: Task | null;
}

export default TaskCardInfo;
