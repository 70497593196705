
import { CDataTableSortVal } from "@/@types";
import {
  AllPayload,
  SortQueryPayload,
  Task,
  TaskAllPayload,
  TaskFilterQueryPayload,
} from "@/store/modules";
import { filterEmptyQuery } from "@/utility";
import { Component, Vue, Watch } from "vue-property-decorator";
import TaskStatusDropdown from "./form/TaskStatusDropdown.vue";
import TaskList from "./TaskList.vue";
import { formatTaskNumber } from "../mr/filters/format-task-number";

@Component({
  components: { TaskList, TaskStatusDropdown },
})
export default class TaskIndex extends Vue {
  limit = 25;
  // loading: boolean = true;

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "datetime",
    order: "desc",
  };

  /**
   * Synced from the CDataTable.
   * {Object}
   */
  sortVal: CDataTableSortVal = {
    column: 'datetime', asc: false
  };

  colFilter: TaskFilterQueryPayload = {};

  filterStatus!: string[];

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  @Watch("colFilter")
  onColFilterChange(
    val: TaskFilterQueryPayload | null,
    oldVal: TaskFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    const k1 = Object.keys(val),
      k2 = Object.keys(oldVal),
      s: string[] = k1.length >= k2.length ? k1 : k2;

    for (let i = 0; i < s.length; i++) {
      const k = s[i];
      const v1 = val[k];
      const v2 = oldVal[k];
      if (v1 !== v2) {
        this.load();
        return;
      }
    }
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: AllPayload) {
    await Task.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Task.query()
      .orderBy(this.sort.sort || "datetime", this.sort.order)
      .withAllRecursive()
      .get();
    return data;
  }

  get loading(): boolean {
    // return true;
    return !!Task.store().state.entities.task.fetching;
  }

  get total() {
    return Task.store().state.entities.task.total;
  }

  get numPages(): number {
    const total = Task.store().state.entities.task.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onStatusSelect(options: string[]) {
    this.filterStatus = options;
    this.load();
  }

  onPageChange(page: number) {
    // {
    //     limit: this.limit,
    //     offset: Math.floor(this.limit * (page - 1)),
    //     ...this.sort
    //   }
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(merge: Partial<TaskAllPayload> = {}): TaskAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): TaskAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);

    if (this.colFilter.taskNumber) {
      // console.log(`${this.colFilter.taskNumber}`,format(`${this.colFilter.taskNumber}`));
      f.taskNumber = formatTaskNumber(`${this.colFilter.taskNumber}`);
    }

    if (this.filterStatus && this.filterStatus.length) {
      f.status = (this.filterStatus || []).join(",");
    }

    return { ...f };
  }
}
