
import { Component, Vue, Prop } from "vue-property-decorator";
import { Task, TaskInterface } from "@/store/modules";

@Component
export default class TaskStatusBadge extends Vue {
  @Prop(Task)
  private record!: TaskInterface;

  text() {
    return this.record.status || "unknown";
  }

  color() {
    switch (this.record.status) {
      case "pending":
        return "warning";
      case "schedulable":
        return "info";
      case "scheduled":
        return "primary";
      case "complete":
        return "success";
    }

    return "secondary";
  }
}
