
import { Component, Vue, Prop } from "vue-property-decorator";
import { Task, TaskInterface } from "@/store/modules";
import { JobCustomerCard } from "@/pages/job";
import { TaskMRList } from "./detail/TaskMRList.vue";
import JobCardInfo from "@/pages/job/JobCardInfo.vue";
import TaskCardInfo from "./TaskCardInfo.vue";
import TaskScheduleListHandler from "../task-schedule/TaskScheduleListHandler.vue";
import TaskMap from "./detail/TaskMap.vue";

@Component({
  components: {
    JobCustomerCard,
    TaskMRList,
    TaskCardInfo,
    JobCardInfo,
    TaskScheduleListHandler,
    TaskMap,
  },
})
export default class TaskDetail extends Vue {
  @Prop([String, Number])
  readonly id!: string | number;

  task: TaskInterface | null = null;

  loading = true;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    const task = this.findRecord();
    if (!task || !task.schedule || !this.hasTechnicianInPayload(task)) {
      this.loading = true;
      await Task.dispatch("findOne", {
        id: +this.id,
        withTechInSchedule: true,
      });
      this.task = this.findRecord();
    } else {
      this.task = task;
    }
  console.log('updateData', this.task);
    this.loading = false;
  }

  hasTechnicianInPayload(task: TaskInterface) {
    if (!task.schedule) {
      // no schedule to check for technicians
      return true;
    }
    return !!(task.schedule || []).filter((s) => !!s.technician).length;
  }

  // get loading(): boolean {
  //   // return true;
  //   return !!Task.store().state.entities.mr.fetching;
  // }

  findRecord(): Task | null {
    return Task.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  get jobId(): number | null {
    const task = this.task;
    return task && task.jobID ? task.jobID : null;
  }

  /*get taskAddresses(): string[] {
    if(this.task && this.task.job && this.task.job.address && this.task.job.address.full_address) {
      return [this.task.job.address.full_address]
    }

    return [];
  }*/

  // why was this commented out?
  async dispatchSchedule() {}
}
